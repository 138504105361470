<template>
  <b-container class="py-md-4">
    <div :class="[{'text-white' : userLogged == null}, 'register_wrapper my-md-5 my-4 p-3']">
      <b-row class="justify-content-center">
        <b-col
          xl="10"
          lg="10"
        > 
          <router-link
            v-if="userLogged == null"
            to="/"
            class="navbar-brand font-weight-bold text-center my-6 px-4 d-none d-block"
          >
            <img
              :src="logo"
              alt="fanpopz-logo"
              fluid
              class="register-logo"
            >
          </router-link>
          <div class="text-center pt-5 mb-5"> 
            <h1 class="title display-5 font-weight-bold mb-4">
              Terms & Conditions
            </h1>
          </div>
          <div
            id="terms"
            class="mb-5"
          />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { getPageContent } from '../api/pageContent';
import { _sendResponse } from '../js/common-function';
import logo from "../assets/logo/logo-white-text.png";

export default {
    name:'TermsAndConditions',
    data() {
        return {
            terms: "",
            userLogged: localStorage.getItem("fullname"),
            logo
        };
    },
    async created() {
        var response = await getPageContent('terms-conditions');
        if  (response.status === false) {
            _sendResponse("error",response.message);
            return false;
        }
        this.terms = response.data.content;
        document.getElementById('terms').innerHTML = this.terms;
        document.getElementById('terms').style.fontWeight = "200";
    }
};
</script>